import React, { Component } from "react"
import Backendless from "backendless"
import { connect } from 'react-redux'
import { loadPersons, getPersons, onPersonCreate, onPersonUpdate, onPersonRemove } from "../store"
import Layout from '../components/layout'
import Button from "react-bootstrap/Button"
import Editor from "../persons/editor"
import DeletePersonConfirmation from "../persons/delete-confirm"

const mapStateToProps = state => {
  const { loading, loaded, error, list: persons } = getPersons(state)

  return {
    loading,
    loaded,
    error,
    persons
  }
}

class AddressBook extends Component {
  state = {
    showEditor              : false,
    editorProps             : null,
    showDeleteConfirmation  : false,
    deleteConfirmationProps : null,
  }

  componentDidMount() {
    this.props.loadPersons()

    this.personRT = Backendless.Data.of('Persons').rt()
    this.personRT.addCreateListener(this.props.onPersonCreate)
    this.personRT.addUpdateListener(this.props.onPersonUpdate)
    this.personRT.addDeleteListener(this.props.onPersonRemove)
  }

  componentWillUnmount() {
    this.personRT.removeCreateListener(this.props.onPersonCreate);
    this.personRT.removeUpdateListener(this.props.onPersonUpdate);
    this.personRT.removeDeleteListener(this.props.onPersonRemove);
  }

  showEditor = person => this.setState({ showEditor: true, editorProps: { person }})
  hideEditor = () => this.setState({ showEditor: false, editorProps: null })

  onAddClick = () => this.showEditor(null)
  onEditClick = person => this.showEditor(person)

  showDeleteConfirmation = person => this.setState({ showDeleteConfirmation: true, deleteConfirmationProps: { person }})
  hideDeleteConfirmation = () => this.setState({ showDeleteConfirmation: false, deleteConfirmationProps: null })
  onDeleteClick = person => this.showDeleteConfirmation(person)

  renderContent() {
    const { loading, error, persons } = this.props

    if (loading) {
      return (
        <div>
          Loading...
        </div>
      )
    }
    if (error) {
      return (
        <div className={"alert alert-danger"}>
          Error: {error}
        </div>
      )
    }

    return (
      <ul className={"list-group"}>
        {persons.map((person, index) => (
          <li key={index} className={"list-group-item d-flex justify-content-between align-items-center"}>
            <div>
              <div>{person.name}</div>
              <div className={"text-muted small"}>{person.address}</div>
            </div>
            <div>
              <Button
                size={"sm"}
                variant={"outline-primary"}
                onClick={() => this.onEditClick(person)}>
                <i className={"far fa-edit"} />
              </Button>
              <Button
                size={"sm"}
                variant={"outline-danger"}
                onClick={() => this.onDeleteClick(person)}>
                <i className={"fas fa-trash"} />
              </Button>
            </div>
          </li>
        ))}
      </ul>
    )
  }

  render() {
    const { showEditor, editorProps, deleteConfirmationProps, showDeleteConfirmation } = this.state

    return (
      <Layout current={"address-book"}>
        <h1>Address Book</h1>
        <div className={"mb-2"}>
          <Button onClick={this.onAddClick}><i className="fas fa-plus" /> Add New Person</Button>
          <Editor {...editorProps} show={showEditor} onHide={this.hideEditor} />
          <DeletePersonConfirmation
            {...deleteConfirmationProps}
            show={showDeleteConfirmation}
            onHide={this.hideDeleteConfirmation}
            />
        </div>
        {this.renderContent()}
      </Layout>
    )
  }
}

export default connect(mapStateToProps, { loadPersons, onPersonCreate, onPersonUpdate, onPersonRemove })(AddressBook)